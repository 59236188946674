export const environment = {
  production: true,

  //Endpoints
  apiLeagueServer: 'https://devcompeteapi.fullswingapps.com/api',
  apiBaymanagerServer: 'https://devlauncher.fsgapi.net/api/fs',
  apiBaymanagerPanel: 'https://devbaymanager.fsgapi.net',
  apiAuthPanel: 'authapi.fullswingapps.com',
  apiAuthServer: 'authapi.fullswingapps.com/api',
  //apiLeagueServer: 'https://localhost:44322/api',
  //apiBaymanagerServer: 'https://localhost:44361/api/fs',
  // apiAuthPanel: 'https://localhost:402',
  // apiAuthServer: 'https://localhost:44362/api',

  hostName: 'devbaymanager.fsgapi.net',

  //Local strorage values
  navName: '_fs_nav_name',
  bayManagerUser: 'Bay-Manager-User',
  calendarState: '_fs_fc_state',

  langId: '_fs_lang_id',
  defaultLanguage: 'en',
  defaultFontFamily: 'DavisSans',

  breakPointScreen: {
    small: 576,
    Medium: 768,
    large: 992,
    extraLarge: 1200,
    xExtraLarge: 1400,
  },
};
