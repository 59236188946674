<div *ngIf="!showMessage; else showMessageTemp" class="page-flow">
    <form *ngIf="!authService.isAuthenticated; else successTemp" autocomplete="off" [formGroup]="signInForm"
        (ngSubmit)="onSubmit()">
        <fs-grid [options]="gridOptions">
            <fs-grid-cell>

                <fs-form-field [invalid]="signInFormControl.email.touched && signInFormControl.email.invalid"
                    (onClearValue)="signInFormControl.email.setValue('')">
                    <label for="email">Email</label>
                    <input fs-input id="email" type="email" [formControl]="signInFormControl.email">
                </fs-form-field>
                <small *ngIf="signInFormControl.email.touched && signInFormControl.email.invalid" fs-small-message
                    [type]="'Error'">Please enter a valid email address</small>

            </fs-grid-cell>
            <fs-grid-cell>

                <fs-form-field [invalid]="signInFormControl.password.touched && signInFormControl.password.invalid"
                    [type]="'password'">
                    <label for="password">Password</label>
                    <input fs-input id="password" type="password" [formControl]="signInFormControl.password">
                </fs-form-field>
                <small *ngIf="signInFormControl.password.touched && signInFormControl.password.invalid" fs-small-message
                    [type]="'Error'">Please enter a password</small>

            </fs-grid-cell>
            <fs-grid-cell>

                <a class="text-end mb-40" (click)="nextPage('reset-password')">Forgot your password?</a>

            </fs-grid-cell>
            <fs-grid-cell>

                <button fs-button [color]="'primary'" type="submit"
                    [disabled]="loaderService.loader || !signInForm.valid">Sign In</button>

            </fs-grid-cell>
        </fs-grid>
    </form>
    <ng-template #successTemp>
        <div class="ph-16">
            <fs-alert [title]="'Successfully Logged In!'" [message]="'Tap below for an awesome simulation experience.'"
                [type]="'Success'" [icon]="'fsi-cir-success-48'"></fs-alert>

            <button fs-button [color]="'primary'" class="mb-16" type="button" (click)="goContinue()">
                Continue
            </button>
            <a class="text-center" (click)="signOut()">Sign Out</a>
        </div>
    </ng-template>
</div>
<ng-template #showMessageTemp>
    <div class="page-flow">
        <div class="ph-16">
            <fs-alert [title]="'Please Check Your Email'"
                [message]="'Please check your email for an authorization link. Click that link, then return to the Full Swing app to complete account verification.'"
                [subTitle]="signInFormControl.email.value" [icon]="'fsi-envelope-48'"></fs-alert>

            <!-- <button fs-button [color]="'primary'" class="mb-16" type="button" (click)="onResendEmail()">
                Re-send Email
            </button> -->
            <a class="text-center" (click)="prevPage('')">Go back</a>
        </div>
    </div>
</ng-template>