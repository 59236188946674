import { Gender } from '../enums/gender.enum';
import { Handedness } from '../enums/handedness.enum';
import { HandicapRange } from '../static-data';
import { BaseDTO } from './base-dto.model';

export class CreatePlayerRequest {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  cognitoSub?: string;
  handedness?: Handedness;
  handicap?: number;
  gender?: Gender;
  profileImageUrl?: string;
  simulatorPreference?: any;

  constructor(model: CreatePlayerRequest) {
    this.firstName = model.firstName?.length ? model.firstName.trim() : '';
    this.lastName = model.lastName?.length ? model.lastName.trim() : '';
    this.phoneNumber = model.phoneNumber?.length ? model.phoneNumber : null;
    this.email = model.email?.length ? model.email.trim() : '';
    this.cognitoSub = model.cognitoSub || '';
    this.handedness = model.handedness || Handedness.None;
    this.handicap = model.handicap || HandicapRange.default;
    this.gender = model.gender || Gender.None;
    this.profileImageUrl = model.profileImageUrl || '';
    this.simulatorPreference = model.simulatorPreference || (null as any);
  }
}

export class UpdatePlayerRequest {
  id?: number;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  cognitoSub?: string;
  handedness?: Handedness;
  handicap?: number;
  gender?: Gender;
  profileImageUrl?: string;
  simulatorPreference?: any;

  constructor(model: UpdatePlayerRequest) {
    this.id = model.id || 0;
    this.firstName = model.firstName?.length ? model.firstName.trim() : '';
    this.lastName = model.lastName?.length ? model.lastName.trim() : '';
    this.phoneNumber = model.phoneNumber?.length ? model.phoneNumber : null;
    this.email = model.email?.length ? model.email.trim() : '';
    this.cognitoSub = model.cognitoSub || '';
    this.handedness = model.handedness || Handedness.None;
    this.handicap = model.handicap || HandicapRange.default;
    this.gender = model.gender || Gender.None;
    this.profileImageUrl = model.profileImageUrl || '';
    this.simulatorPreference = model.simulatorPreference || (null as any);
  }
}

export class PlayerResult extends BaseDTO {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  cognitoSub?: string;
  handedness?: Handedness;
  handicap?: number;
  gender?: Gender;
  profileImageUrl?: string;
  simulatorPreference?: any;
  events?: any[];
  playerLocations?: any[];
  rounds?: any[];

  constructor(model: PlayerResult) {
    super(model);

    this.firstName = model.firstName || '';
    this.lastName = model.lastName || '';
    this.phoneNumber = model.phoneNumber || '';
    this.email = model.email || '';
    this.cognitoSub = model.cognitoSub || '';
    this.handedness = model.handedness || Handedness.None;
    this.handicap = model.handicap || HandicapRange.default;
    this.gender = model.gender || Gender.None;
    this.profileImageUrl = model.profileImageUrl || '';
    this.simulatorPreference = model.simulatorPreference || (null as any);
    this.events = model.events || (null as any);
    this.playerLocations = model.playerLocations || (null as any);
    this.rounds = model.rounds || (null as any);
  }
}

export class GetExternalPlayerResult extends PlayerResult {
  //Custom fields
  asNotification?: boolean;

  constructor(model: GetExternalPlayerResult) {
    super(model);

    this.asNotification = model.asNotification || false;
  }
}

export class ChangePasswordObject {
  code?: string;
  username?: string;

  constructor(model: ChangePasswordObject) {
    this.code = model.code || '';
    this.username = model.username || '';
  }
}
