import { GlobalService } from './shared/services/global.service';
import { Component, OnInit } from '@angular/core';
import { LoggerService } from './shared/services/logger.service';
import { AuthService } from './shared/services/auth.service';
import { FsPageLoaderService } from './shared/modules/fs-controls/fs-page-loader/fs-page-loader.service';
import { RedirectionService } from './shared/services/redirect.service';
import { UrlParameterFor } from './shared/enums/app.enum';
import { ConfirmUserModel, SessionModel } from './shared/models/parameters';
import { Router } from '@angular/router';
import { ConfirmAccountState } from './shared/enums/auth.enum';
import { AWSResponseType } from './shared/services/error-auth';
import { FsLoaderService } from './shared/modules/fs-controls/fs-loader/fs-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  applicationReady: boolean;

  constructor(
    private loggerService: LoggerService,
    public authService: AuthService,
    public pageLoader: FsPageLoaderService,
    private loaderService: FsLoaderService,
    private redirectService: RedirectionService,
    public router: Router,
    public globalService: GlobalService
  ) {
    this.applicationReady = false;

    this.loggerService.printVersion();
    this.globalService.checkConfirmationState();
    this.redirectService.checkQueryParameters();
  }

  async ngOnInit(): Promise<void> {
    try {
      this.pageLoader.loader = true;

      const result = await this.authService.isTokenValid();
      if (!result) await this.clearAuth();
      else {
        const player = await this.authService.getCompetePlayer();
        if (!player) {
          await this.clearAuth();
          alert('You are authenticated, but your account was not found.');
          return;
        } else {
          this.globalService.player = player;
          this.globalService.currentConfirmAccount =
            ConfirmAccountState.Confirmed;
          this.authService.isAuthenticated = true;
        }
      }

      await this.checkSessionId();
      await this.checkConfirmationAccount();

      if (this.redirectService.urlState === UrlParameterFor.ResetPassword) {
        this.router.navigateByUrl('/reset-password');
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.pageLoader.loader = false;
      this.applicationReady = true;
    }
  }

  async checkSessionId(): Promise<void> {
    if (this.redirectService.urlState === UrlParameterFor.SessionId) {
      if (
        this.authService.isAuthenticated &&
        this.globalService.checkAccountIsCompleted()
      ) {
        const joinResult = await this.authService.joinSession(
          (this.redirectService.paramData as SessionModel).sessionId
        );

        switch (joinResult.status.toLowerCase()) {
          case 'already joined':
            alert('Already Joined');
            break;
          case 'error':
            alert('Session has expired');
            break;
          case 'success':
            alert('Successful joined');
            break;
          default:
            break;
        }
      } else {
        window.localStorage.setItem(
          'SessionId',
          this.redirectService.paramData.sessionId
        );

        if (
          this.authService.isAuthenticated &&
          !this.globalService.checkAccountIsCompleted()
        ) {
          this.router.navigateByUrl('/create-account');
        } else {
          this.router.navigateByUrl('/sign-in');
        }
      }
    }
  }

  async checkConfirmationAccount(): Promise<void> {
    if (this.redirectService.urlState === UrlParameterFor.ConfirmAccount) {
      const isConfirmed = await this.authService.confirmSignUp(
        new ConfirmUserModel({
          ...this.redirectService.paramData,
        })
      );

      if (isConfirmed.type === AWSResponseType.Success) {
        this.globalService.currentConfirmAccount =
          ConfirmAccountState.Confirmed;

        this.router.navigateByUrl('/account-verified');
      }
    }
  }

  async clearAuth(): Promise<void> {
    await this.authService.unautorized([
      'AccountEmail',
      'ConfirmAccount',
      '_fs_cp_page_his',
      'SignUpResponse',
      'SessionId',
    ]);
  }

  async signOut(): Promise<void> {
    this.loaderService.loader = true;
    await this.authService.unautorized(['_fs_cp_page_his']);
    this.router.navigateByUrl('/');
    this.loaderService.loader = false;
  }
}
