import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsPasswordValidityComponent } from './fs-password-validity.component';
import { FsSmallMessageDirective } from '../../fs-directives/fs-small-message.directive';

@NgModule({
  declarations: [FsPasswordValidityComponent],
  imports: [CommonModule, FsSmallMessageDirective],
  exports: [FsPasswordValidityComponent],
})
export class FsPasswordValidityModule {}
