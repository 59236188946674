<div class="page-flow">
    <div class="slide">
        <div class="banner">
            <div class="banner-image">
                <img src="../../../assets/images/splash-banner.png" />
                <p class="caption">Access Your Numbers, On-Demand</p>
            </div>
            <div class="blue-rect"></div>
            <p class="description">Access all of your club statistics, shot data, swing videos and more.</p>
        </div>
    </div>

    <div class="bottom-slot">
        <button *ngIf="!viewProfile; else viewProfileButton" fs-button [color]="'primary'" type="button"
            (click)="nextPage('create-account')">
            {{ accountButtonTitle }}
        </button>
        <ng-template #viewProfileButton>
            <button fs-button [color]="'primary'" type="button" (click)="nextPage('profile')">
                {{ accountButtonTitle }}
            </button>
        </ng-template>

        <button fs-button [outline]="true" type="button" (click)="nextPage('sign-in')">
            I already have an account
        </button>
    </div>
</div>