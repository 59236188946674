import { ElementRef, TemplateRef } from '@angular/core';

export class SelectItem {
  id?: number;
  index?: number;
  name?: string;
  value?: any;
  classes?: string;
  hasCustom?: boolean;
  template?: TemplateRef<any>;
  isActive?: boolean;
  imageSrc?: string;
  childs?: SelectItem[];

  constructor(model: SelectItem) {
    this.id = model.id || 0;
    this.index = model.index || 0;
    this.name = model.name || '';
    this.value = model.value;
    this.classes = model.classes || '';
    this.hasCustom = model.hasCustom || false;
    this.template = model.template || (null as any);
    this.isActive = model.isActive;
    this.imageSrc = model.imageSrc || '';
    this.childs = model.childs || (null as any);
  }
}

export enum FSActionElementType {
  Link = 1,
  Button = 2,
}

export enum FsColorPalette {
  Primary = 1,
  Success = 2,
  Warning = 3,
  Danger = 4,
  Black = 5,
  LightGray = 6,
}

export enum FsTextTransformType {
  Default = 0,
  Capitalize = 1,
  Lowercase = 2,
  Uppercase = 3,
  Inherit = 4,
  Custom = 5,
}

export declare type FsColorPaletteSymbols =
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'black'
  | 'light-gray'
  | '';

export declare type FsWidthSymbols =
  | 'unset'
  | 'inherit'
  | 'max-content'
  | 'min-content'
  | string;

export declare type FsMessageTypeSymbols =
  | 'Success'
  | 'Warning'
  | 'Error'
  | 'Info'
  | 'Normal';

export declare type FsControlAppearance =
  | 'None'
  | 'Flat'
  | 'Outline'
  | 'Underline'
  | 'Float';

export declare type FsActionState = 'Link' | 'Button';

export class FSActionElement<V> {
  id?: number;
  type?: FSActionElementType;
  name?: string;
  value?: V;
  role?: string;
  color?: FsColorPaletteSymbols;
  classes?: string;
  isActive?: boolean;
  action?: () => {};

  constructor(model: FSActionElement<V>) {
    this.id = model.id || 0;
    this.type = model.type || FSActionElementType.Button;
    this.name = model.name || '';
    this.value = model.value || (null as any);
    this.role = model.role || '';
    this.color = model.color || '';
    this.classes = model.classes || '';
    this.isActive = model.isActive || true;
    this.action = model.action || (null as any);
  }
}

export enum InlinePosition {
  Start = 1,
  Center = 2,
  End = 3,
}

export interface IBoundingClientRect {
  bottom?: number;
  height?: number;
  left?: number;
  right?: number;
  top?: number;
  width?: number;
  x?: number;
  y?: number;
  id?: string;
}

export class ElementEvent<V> {
  event?: any;
  value?: V;

  constructor(model: ElementEvent<V>) {
    this.event = model.event || (null as any);
    this.value = model.value || (null as any);
  }
}

export class FsOverlayOptions {
  parentEl: HTMLElement;
  contentRef: HTMLElement;
  contentItemsCount: number;
  maxItemsPerView?: number;
  leftOffset?: number;
  topOffset?: number;
  width?: number;
  classes?: string;

  constructor(model: FsOverlayOptions) {
    this.parentEl = model.parentEl;
    this.contentRef = model.contentRef;
    this.contentItemsCount = model.contentItemsCount;
    this.maxItemsPerView = model.maxItemsPerView || 8;
    this.leftOffset = model.leftOffset || 0;
    this.topOffset = model.topOffset || 0;
    this.width = model.width || 0;
    this.classes = model.classes || '';
  }
}

export class FsOverlayContentOptions {
  parentRect: IBoundingClientRect;
  contentRect: IBoundingClientRect;
  parentOptions: FsOverlayOptions;
  overlayContainer: HTMLElement;

  constructor(model: FsOverlayContentOptions) {
    this.parentRect = model.parentRect;
    this.contentRect = model.contentRect;
    this.parentOptions = model.parentOptions;
    this.overlayContainer = model.overlayContainer;
  }
}

export class FsDragEndEvent {
  oldIndex?: number;
  newIndex?: number;

  constructor(model: FsDragEndEvent) {
    this.oldIndex = model.oldIndex || 0;
    this.newIndex = model.newIndex || 0;
  }
}

export enum SortDirection {
  Default = 0,
  ASC = 1,
  DESC = 2,
}

export class SortElementOption {
  direction?: SortDirection;
  elName?: string;

  constructor(model: SortElementOption) {
    this.direction = model.direction || SortDirection.Default;
    this.elName = model.elName || '';
  }
}
