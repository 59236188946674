<ul class="fs-password-validity">
    <li>
        <small fs-small-message [type]="isPasswordUpperCase ? 'Success' : 'Normal'">
            1 lowercase letter
        </small>
    </li>
    <li>
        <small fs-small-message [type]="isPasswordUpperCase ? 'Success' : 'Normal'">
            1 uppercase letter
        </small>
    </li>
    <li>
        <small fs-small-message [type]="isPasswordHasSpecialCharacter ? 'Success' : 'Normal'">
            1 special character
        </small>
    </li>
    <li>
        <small fs-small-message [type]="isPasswordHasNumber ? 'Success' : 'Normal'">
            1 number
        </small>
    </li>
    <li>
        <small fs-small-message [type]="isPasswordUpTo8Characters ? 'Success' : 'Normal'">
            At least 8 characters
        </small>
    </li>
    <li>
        <small fs-small-message [type]="isMatchPasswords ? 'Success' : 'Normal'">
            Passwords must match
        </small>
    </li>
</ul>