import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsLoaderComponent } from './fs-loader.component';
import { FsSpinnerModule } from '../fs-spinner/fs-spinner.module';

@NgModule({
  declarations: [FsLoaderComponent],
  imports: [CommonModule, FsSpinnerModule],
  exports: [FsLoaderComponent],
})
export class FsLoaderModule {}
