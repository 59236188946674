import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { FsMessageTypeSymbols } from '../../fs-controls-shared.model';
import { sleep } from 'src/app/shared/helper-functions';

@Component({
  selector: 'fs-alert',
  templateUrl: './fs-alert.component.html',
  styleUrls: ['./fs-alert.component.scss'],
})
export class FsAlertComponent implements AfterViewInit {
  @ViewChild('alertIconRef') alertIconRef: ElementRef<HTMLElement>;

  @Input('icon') icon: string;
  @Input('title') title: string;
  @Input('subTitle') subTitle: string;
  @Input('message') message: string;
  @Input('type') type: FsMessageTypeSymbols;

  constructor() {
    this.title = this.message = this.subTitle = this.icon = '';
    this.type = 'Normal';
  }

  async ngAfterViewInit(): Promise<void> {
    await sleep(300);

    this.alertIconRef.nativeElement.classList.add('full-opacity', 'rotate-360');
  }
}
