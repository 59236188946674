import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsImageComponent } from './fs-image.component';

@NgModule({
  declarations: [FsImageComponent],
  imports: [CommonModule],
  exports: [FsImageComponent],
})
export class FsImageModule {}
