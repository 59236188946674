<div class="page-flow">
    <form *ngIf="currentPageState === pageState.InputEmail" autocomplete="off" [formGroup]="forgotEmailForm"
        (ngSubmit)="onSubmit()">
        <fs-grid [options]="gridOptions1">
            <fs-grid-cell>

                <p class="text-center">Enter the email associated with your Full Swing account to receive password reset
                    instructions.</p>

            </fs-grid-cell>
            <fs-grid-cell>

                <fs-form-field
                    [invalid]="forgotEmailFormControls.email.touched && forgotEmailFormControls.email.invalid"
                    (onClearValue)="forgotEmailFormControls.email.setValue('')">
                    <label for="email">Email</label>
                    <input fs-input id="email" type="email" [formControl]="forgotEmailFormControls.email">
                </fs-form-field>
                <small *ngIf="forgotEmailFormControls.email.touched && forgotEmailFormControls.email.invalid"
                    fs-small-message [type]="'Error'">Please enter a valid email address</small>

            </fs-grid-cell>

            <fs-grid-cell>

                <button fs-button [color]="'primary'" type="submit"
                    [disabled]="loaderService.loader || !forgotEmailForm.valid">Reset Password</button>

            </fs-grid-cell>
            <fs-grid-cell>

                <a class="text-center" (click)="prevPage('sign-in')">Return to sign in page</a>

            </fs-grid-cell>
        </fs-grid>
    </form>

    <div *ngIf="currentPageState === pageState.SentEmail" class="ph-16">
        <fs-alert [title]="'Please Check Your Email'"
            [message]="'We have sent a password reset code/link to your email. Please check your email.'"
            [subTitle]="forgotEmailFormControls.email.value" [icon]="'fsi-envelope-48'"></fs-alert>

        <a class="text-center" (click)="prevPage('')">Go back</a>
    </div>

    <form *ngIf="currentPageState === pageState.NewPasswords" autocomplete="off" [formGroup]="resetPasswordForm"
        (ngSubmit)="onSubmit()">
        <fs-grid [options]="gridOptions2">
            <fs-grid-cell>

                <p class="text-center">If a Full Swing account is associated with the credentials provided, you’ll
                    receive a message with a verification code.</p>

            </fs-grid-cell>
            <fs-grid-cell>

                <fs-form-field
                    [invalid]="resetPasswordFormControls.code.touched && resetPasswordFormControls.code.invalid"
                    (onClearValue)="resetPasswordFormControls.code.setValue('')">
                    <label for="code">Verification Code</label>
                    <input id="code" type="text" [formControl]="resetPasswordFormControls.code">
                </fs-form-field>
                <small *ngIf="resetPasswordFormControls.code.touched && resetPasswordFormControls.code.invalid"
                    fs-small-message [type]="'Error'">Please enter your verification code</small>

            </fs-grid-cell>
            <fs-grid-cell>

                <fs-form-field [type]="'password'"
                    [invalid]="resetPasswordFormControls.newPassword.touched && resetPasswordFormControls.newPassword.invalid">
                    <label for="newPassword">New Password</label>
                    <input id="newPassword" type="password" [formControl]="resetPasswordFormControls.newPassword">
                </fs-form-field>
                <small class="mb-16"
                    *ngIf="resetPasswordFormControls.newPassword.touched && resetPasswordFormControls.newPassword.invalid"
                    fs-small-message [type]="'Error'">Password does not meet the requirements below</small>

                <fs-password-validity [newPassword]="resetPasswordFormControls.newPassword"
                    [confirmPassword]="resetPasswordFormControls.againPassword"
                    (validationResult)="isValidPassword = $event"></fs-password-validity>

            </fs-grid-cell>
            <fs-grid-cell>

                <fs-form-field [type]="'password'"
                    [invalid]="resetPasswordFormControls.againPassword.touched && resetPasswordFormControls.againPassword.invalid">
                    <label for="againPassword">Confirm New Password</label>
                    <input id="againPassword" type="password" [formControl]="resetPasswordFormControls.againPassword">
                </fs-form-field>
                <small
                    *ngIf="resetPasswordFormControls.againPassword.touched && resetPasswordFormControls.againPassword.invalid"
                    fs-small-message [type]="'Error'">Password does not meet the requirements below</small>

            </fs-grid-cell>
            <fs-grid-cell>

                <button fs-button [color]="'primary'" type="submit"
                    [disabled]="!isValidPassword || loaderService.loader || !resetPasswordForm.valid">
                    Reset Password
                </button>

            </fs-grid-cell>
            <!-- <fs-grid-cell>

                <a class="text-center" (click)="onSubmitForgotEmail()">Re-send verification code</a>

            </fs-grid-cell> -->
            <fs-grid-cell>

                <a class="text-center" (click)="currentPageState = pageState.InputEmail">Go back</a>

            </fs-grid-cell>
        </fs-grid>
    </form>

    <div *ngIf="currentPageState === pageState.Successfuly" class="ph-16">
        <fs-alert [title]="'Password Changed!'" [message]="'Tap below to sign in with your new password.'"
            [icon]="'fsi-cir-success-48'"></fs-alert>

        <button fs-button [color]="'primary'" type="button" (click)="nextPage('sign-in')">
            Sign In
        </button>
    </div>
</div>