import { NavigationExtras } from '@angular/router';
import { FsPageFrameService } from './fs-page-frame.service';

export abstract class FsPageAction {
  public get title(): string {
    return this.frameService.title;
  }
  public set title(v: string) {
    this.frameService.title = v;
  }

  constructor(public frameService: FsPageFrameService) {}

  protected nextPage(path: string, extras?: NavigationExtras): void {
    let fromPath = window.location.pathname;

    if (path[0] !== '/') path = `/${path}`;

    this.frameService.goNextPage(path, fromPath, extras);
  }

  protected prevPage(path: string, extras?: NavigationExtras): void {
    this.frameService.goPrevPage(path, extras);
  }

  // protected showPage(): void {}

  // protected dismissPage(): void {}
}
