<div #fsListSelector class="fs-list-selector" [ngClass]="classes">

    <div class="list-selector-header text-overflow">

        <div>
            <img *ngIf="currentValue.imageSrc?.length" [src]="currentValue.imageSrc">
            <h4 *ngIf="!hideLabel" [style]="{ 'width': width ? (width - 40) + 'px' : 'unset' }">
                {{ currentValue?.name?.length ? currentValue.name : placeholder }}
            </h4>
        </div>

        <ng-container *ngIf="!hideList">
            <i class="ico x10 fsi-arrow-down" [class]="isExpanded ? 'x-rotate-180' : 'x-rotate-0'"></i>
            <a class="mask-link" (click)="onClickExpandList()"></a>
        </ng-container>

    </div>

    <div *ngIf="isExpanded" #fsListItemsRef class="overlay-content list-selector-body d-none">
        <ul class="parent-list">
            <li *ngFor="let item of options">
                <ng-container *ngIf="hasNedtedValues; else singleOptionTemp">

                    <span>{{ item.name }}</span>

                    <ul *ngIf="item.childs?.length" class="child-list">
                        <li *ngFor="let child of item.childs">

                            <a *ngIf="child.isActive; else deactiveChildTemp"
                                [id]="child.value === value ? ('item-' + child.value) : ''"
                                [ngClass]="child.value === value ? 'active-select-item' : ''"
                                (click)="onClickItem(child)">
                                <img *ngIf="item.imageSrc?.length" [src]="item.imageSrc">
                                {{ child.name }}
                            </a>

                            <ng-template #deactiveChildTemp>
                                <a class="fs-el-disabled">
                                    <img *ngIf="item.imageSrc?.length" [src]="item.imageSrc">
                                    {{ child.name }}
                                </a>
                            </ng-template>

                        </li>
                    </ul>

                </ng-container>
                <ng-template #singleOptionTemp>

                    <a *ngIf="item.isActive; else deactiveItemTemp"
                        [id]="item.value === value ? ('item-' + item.value) : ''"
                        [ngClass]="item.value === value ? 'active-select-item' : ''" (click)="onClickItem(item)">
                        <img *ngIf="item.imageSrc?.length" [src]="item.imageSrc">
                        {{ item.name }}
                    </a>

                    <ng-template #deactiveItemTemp>
                        <a class="fs-el-disabled">
                            <img *ngIf="item.imageSrc?.length" [src]="item.imageSrc">
                            {{ item.name }}
                        </a>
                    </ng-template>

                </ng-template>
            </li>
        </ul>
    </div>
</div>