import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FsPageFrameService } from './fs-page-frame.service';

@Component({
  selector: 'fs-page-frame',
  templateUrl: './fs-page-frame.component.html',
  styleUrls: ['./fs-page-frame.component.scss'],
})
export class FsPageFrameComponent implements AfterViewInit {
  @ViewChild('frameContentTemp') frameContentTemp: ElementRef<HTMLElement>;

  constructor(public frameService: FsPageFrameService) {}

  ngAfterViewInit(): void {
    this.frameService.frameContent = this.frameContentTemp.nativeElement;
  }
}
