import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FsGridOptions } from './fs-grid.model';
import { debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'fs-grid',
  templateUrl: './fs-grid.component.html',
  styleUrls: ['./fs-grid.component.scss'],
})
export class FsGridComponent implements OnInit, AfterViewInit {
  @ViewChild('gridRef') gridRef: ElementRef<HTMLElement>;
  @Input('options') options: FsGridOptions;

  contentData: HTMLElement[];

  constructor() {
    this.options = new FsGridOptions({});
    this.contentData = new Array<HTMLElement>();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderGrid();

    // fromEvent(window, 'resize')
    //   .pipe(debounceTime(250))
    //   .subscribe((_) => this.renderGrid());
  }

  backupContent(): void {
    Array.from(this.gridRef.nativeElement.children)?.forEach(
      (item: HTMLElement) => {
        this.contentData.push(item);
      }
    );

    this.gridRef.nativeElement.innerHTML = '';
  }

  selectScreenSize(): number[][] {
    const screenSize = window.screen.width;

    if (screenSize >= 1400) {
      return this.options.xxl;
    } else if (screenSize >= 1200) {
      return this.options.xl;
    } else if (screenSize >= 992) {
      return this.options.lg;
    } else if (screenSize >= 768) {
      return this.options.md;
    } else if (screenSize >= 576) {
      return this.options.sm;
    } else if (screenSize < 576) {
      return this.options.xsm;
    } else {
      return null as any;
    }
  }

  createGridCells(cellData: number[][]): void {
    if (!cellData) return;

    let contentDataIndex = 0;
    const rowLen = cellData.length;

    for (let i = 0; i < rowLen; i++) {
      const colLen = cellData[i].length;
      const row = document.createElement('div');
      row.classList.add('row');

      for (let j = 0; j < colLen; j++) {
        const col = document.createElement('div');
        col.classList.add(`col-${cellData[i][j]}`);

        if (this.contentData.length > contentDataIndex)
          col.appendChild(this.contentData[contentDataIndex]);

        row.appendChild(col);

        contentDataIndex++;
      }

      this.gridRef.nativeElement.appendChild(row);
    }
  }

  renderGrid(): void {
    this.backupContent();
    this.createGridCells(this.selectScreenSize());
  }
}
