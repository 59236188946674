import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FsMessageTypeSymbols } from '../fs-controls-shared.model';

@Directive({
  standalone: true,
  selector: '[fs-small-message]',
})
export class FsSmallMessageDirective
  implements OnInit, AfterViewInit, OnChanges
{
  @Input('type') type: FsMessageTypeSymbols;

  constructor(private element: ElementRef<HTMLElement>) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type']) {
      this.setType();
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    (<HTMLElement>this.element.nativeElement).classList.add('fs-small-message');
    this.setType();
  }

  setType(): void {
    (<HTMLElement>this.element.nativeElement).classList.remove(
      'error',
      'success',
      'warning',
      'info',
      'normal'
    );

    switch (this.type) {
      case 'Error':
        (<HTMLElement>this.element.nativeElement).classList.add('error');
        break;
      case 'Success':
        (<HTMLElement>this.element.nativeElement).classList.add('success');
        break;
      case 'Warning':
        (<HTMLElement>this.element.nativeElement).classList.add('warning');
        break;
      case 'Info':
        (<HTMLElement>this.element.nativeElement).classList.add('info');
        break;
        case 'Normal':
        (<HTMLElement>this.element.nativeElement).classList.add('normal');
        break;
      default:
        break;
    }
  }
}
