import { Injectable } from '@angular/core';
import { PlayerResult } from '../models/player.model';
import { Subject } from 'rxjs';
import { getUpperFirstChars } from '../helper-functions';
import { VsResult } from '../enums/vs-result.enum';
import { Handedness } from '../enums/handedness.enum';
import { Gender } from '../enums/gender.enum';
import { ConfirmAccountState } from '../enums/auth.enum';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private playerSub = new Subject<PlayerResult>();
  public playerSub$ = this.playerSub.asObservable();

  private _player: PlayerResult;
  public get player(): PlayerResult {
    return this._player;
  }
  public set player(v: PlayerResult) {
    if (v) {
      this._player = new PlayerResult({ ...v });
    } else {
      this._player = null;
    }

    this.playerSub.next(this._player);
    this.setImageName();
  }

  private _imageProfileName: string;
  public get imageProfileName(): string {
    return this._imageProfileName;
  }
  public set imageProfileName(v: string) {
    this._imageProfileName = v;
  }

  private _currentConfirmAccount: ConfirmAccountState;
  public get currentConfirmAccount(): ConfirmAccountState {
    return this._currentConfirmAccount;
  }
  public set currentConfirmAccount(v: ConfirmAccountState) {
    this._currentConfirmAccount = v;
    window.localStorage.setItem('ConfirmAccount', v.toString());
  }

  constructor() {
    this._player = null;
    this._imageProfileName = '';
  }

  public getVsResult(value: VsResult): string {
    switch (value) {
      case VsResult.Loss:
        return 'Loss';
      case VsResult.Win:
        return 'Win';
      case VsResult.Tie:
        return 'Tie';
      default:
        return '';
    }
  }

  public setImageName(): void {
    if (this._player) {
      this._imageProfileName = getUpperFirstChars(
        `${this._player.firstName?.replace(
          ' ',
          ''
        )} ${this._player.lastName?.replace(' ', '')}`
      );
    }
  }

  public checkAccountIsCompleted(): boolean {
    if (
      this.player.gender === Gender.None ||
      this.player.handedness === Handedness.None ||
      this.player.handicap === null
    ) {
      return false;
    }

    return true;
  }

  public checkConfirmationState(): void {
    try {
      const state = window.localStorage.getItem('ConfirmAccount');

      if (state) {
        this.currentConfirmAccount = parseInt(state);
      } else {
        this.currentConfirmAccount = ConfirmAccountState.BeforeCreation;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
