import { Injectable } from '@angular/core';
import { convertUrlParamsToObject } from '../helper-functions';
import { UrlParameterFor } from '../enums/app.enum';
import { ConfirmUserModel, SessionModel } from '../models/parameters';

@Injectable({
  providedIn: 'root',
})
export class RedirectionService {
  private _paramData: any;
  public get paramData(): any {
    return this._paramData;
  }
  public set paramData(v: any) {
    this._paramData = v;
  }

  private _urlState: UrlParameterFor;
  public get urlState(): UrlParameterFor {
    return this._urlState;
  }
  public set urlState(v: UrlParameterFor) {
    this._urlState = v;
  }

  constructor() {
    this._urlState = UrlParameterFor.None;
    this._paramData = null;
  }

  public checkQueryParameters(): void {
    try {
      let queryArr = window.location.href.split('?');
      let paramData = convertUrlParamsToObject(queryArr[1]) ?? 0;

      if (paramData) {
        if (paramData?.type?.length) {
          if (paramData?.code?.length && paramData?.username?.length) {
            // this.router.navigateByUrl('/create-account');
            this._paramData = new ConfirmUserModel({
              code: paramData.code,
              username: paramData.username,
              type: parseInt(paramData.type),
            });

            if (this._paramData.type === 1) {
              this._urlState = UrlParameterFor.ResetPassword;
              return;
            }

            if (this._paramData.type === 2) {
              this._urlState = UrlParameterFor.ConfirmAccount;
              return;
            }
          }
        } else {
          if (paramData?.sessionId?.length) {
            // this.router.navigateByUrl('/sign-in');
            this._paramData = new SessionModel({
              sessionId: paramData.sessionId,
            });

            this._urlState = UrlParameterFor.SessionId;
            return;
          }
        }
      }

      this._urlState = UrlParameterFor.None;
    } catch (error) {
      console.error(error);
    }
  }
}
