import { Component } from '@angular/core';
import { FsPageAction } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-action';
import { FsPageFrameService } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-frame.service';

@Component({
  selector: 'qr-code-entry',
  templateUrl: './qr-code-entry.component.html',
  styleUrls: ['./qr-code-entry.component.scss'],
})
export class QrCodeEntryComponent extends FsPageAction {
  constructor(frameService: FsPageFrameService) {
    super(frameService);
    this.title = 'QR Code Entry';
  }
}
