import { Component } from '@angular/core';
import { FsPageLoaderService } from './fs-page-loader.service';

@Component({
  selector: 'fs-page-loader',
  templateUrl: './fs-page-loader.component.html',
  styleUrls: ['./fs-page-loader.component.scss'],
})
export class FsPageLoaderComponent {
  constructor(public loaderService: FsPageLoaderService) {}
}
