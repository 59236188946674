import { Component } from '@angular/core';
import { FsPageAction } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-action';
import { FsPageFrameService } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-frame.service';

@Component({
  selector: 'account-verified',
  templateUrl: './account-verified.component.html',
  styleUrls: ['./account-verified.component.scss'],
})
export class AccountVerifiedComponent extends FsPageAction {
  constructor(frameService: FsPageFrameService) {
    super(frameService);
    this.title = 'Account Verified';
  }
}
