export class ConfirmUserModel {
  username?: string;
  code?: string;
  type?: number;

  constructor(model: ConfirmUserModel) {
    this.username = model.username || '';
    this.code = model.code || '';
    this.type = model.type || 0;
  }
}

export class SessionModel {
  sessionId?: string;

  constructor(model: SessionModel) {
    this.sessionId = model.sessionId || '';
  }
}
