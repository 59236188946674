import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationId } from '../enums/api.enum';
import { buildQueryParams } from '../helper-functions';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private _url: string;

  public get getUrl(): string {
    return this._url;
  }

  constructor(private httpClient: HttpClient) {
    this._url = environment.apiAuthServer;
  }

  public setApiUrl(appId: ApplicationId = 'FS_AUTH'): void {
    switch (appId) {
      case 'FS_AUTH':
        this._url = environment.apiAuthServer;
        break;
      case 'FS_COMPETE':
        this._url = environment.apiLeagueServer;
        break;
      case 'FS_LAUNCHER':
        this._url = environment.apiBaymanagerServer;
        break;
      default:
        this._url = environment.apiAuthServer;
        break;
    }
  }

  public getItemByQuery<T>(
    appId: ApplicationId,
    path: string,
    queryModel: any
  ): Observable<T> {
    this.setApiUrl(appId);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .get<T>(url, {
        params: buildQueryParams(queryModel),
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public postItemByQuery<T>(
    appId: ApplicationId,
    path: string,
    queryModel: any,
    data: any = null
  ): Observable<T> {
    this.setApiUrl(appId);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .post<T>(url, data, {
        params: buildQueryParams(queryModel),
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public getItems<T>(appId: ApplicationId, path: string): Observable<T> {
    this.setApiUrl(appId);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.get<T>(url).pipe((res) => {
      const p = res || null;
      return p;
    });
  }

  public getItemById<T>(
    appId: ApplicationId,
    id: any,
    path: string
  ): Observable<T> {
    this.setApiUrl(appId);

    const url = this._url + path + `\\${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.get<T>(url).pipe((res) => {
      const p = res || null;
      return p;
    });
  }

  public createItem<T>(
    appId: ApplicationId,
    data: any,
    path: string
  ): Observable<T> {
    this.setApiUrl(appId);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.post<T>(url, data).pipe((res) => {
      const p = res || null;
      return p;
    });
  }

  public updateItem<T>(
    appId: ApplicationId,
    data: any,
    path: string
  ): Observable<T> {
    this.setApiUrl(appId);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.put<T>(url, data).pipe((res) => {
      const p = res || null;
      return p;
    });
  }

  public removeItem<T>(
    appId: ApplicationId,
    data: any,
    path: string
  ): Observable<T> {
    this.setApiUrl(appId);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient
      .delete<T>(url, {
        params: {
          ...data,
        },
      })
      .pipe((res) => {
        const p = res || null;
        return p;
      });
  }

  public removeItemFromBody(
    appId: ApplicationId,
    data: any,
    path: string
  ): Observable<any> {
    this.setApiUrl(appId);

    const url = this._url + path;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.delete<any>(url, { body: data }).pipe((res) => {
      const p = res || null;
      return p;
    });
  }
}
