export class UserAccountModel {
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;

  constructor(model: UserAccountModel) {
    this.email = model.email || '';
    this.password = model.password || '';
    this.firstName = model.firstName || '';
    this.lastName = model.lastName || '';
  }
}

export class AuthResult {
  result?: boolean;
  message?: string;

  constructor(model: AuthResult) {
    this.result = model.result || false;
    this.message = model.message || '';
  }
}
