export class FSFormField {
  id?: number;
  name?: string;
  classes?: string;

  constructor(model: FSFormField) {
    this.id = model.id || 0;
    this.name = model.name || '';
    this.classes = model.classes || '';
  }
}
