import { Gender } from './enums/gender.enum';
import { Handedness } from './enums/handedness.enum';
import { SelectItem } from './modules/fs-controls-shared.model';

export const HandicapRange = {
  min: 0,
  max: 54,
  default: null,
};

export const HandednessData: Array<SelectItem> = [
  new SelectItem({
    id: 1,
    name: 'Right',
    value: Handedness.Right,
    isActive: true,
  }),
  new SelectItem({
    id: 2,
    name: 'Left',
    value: Handedness.Left,
    isActive: true,
  }),
];

export const GenderData: Array<SelectItem> = [
  new SelectItem({
    id: 1,
    name: 'Male',
    value: Gender.Male,
    isActive: true,
  }),
  new SelectItem({
    id: 2,
    name: 'Female',
    value: Gender.Female,
    isActive: true,
  }),
];
