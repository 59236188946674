<form autocomplete="off" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
    <div class="page-flow">
        <fs-grid [options]="gridOptions">
            <fs-grid-cell>
    
                <fs-form-field [type]="'password'"
                    [invalid]="passwordFormControls.current.touched && passwordFormControls.current.invalid">
                    <label for="currentPassword">Current Password</label>
                    <input id="currentPassword" type="password" [formControl]="passwordFormControls.current">
                </fs-form-field>
                <small class="mb-16"
                    *ngIf="passwordFormControls.current.touched && passwordFormControls.current.invalid"
                    fs-small-message [type]="'Error'">Please enter a valid password</small>
    
                <a class="text-end">Forgot your password?</a>
    
            </fs-grid-cell>
            <fs-grid-cell>
    
                <fs-form-field [type]="'password'"
                    [invalid]="passwordFormControls.new.touched && passwordFormControls.new.invalid">
                    <label for="newPassword">New Password</label>
                    <input id="newPassword" type="password" [formControl]="passwordFormControls.new">
                </fs-form-field>
                <small class="mb-16" *ngIf="passwordFormControls.new.touched && passwordFormControls.new.invalid"
                    fs-small-message [type]="'Error'">Please enter a valid password</small>
    
                <fs-password-validity [newPassword]="passwordFormControls.new"
                    [confirmPassword]="passwordFormControls.confirm"
                    (validationResult)="isValidPassword = $event"></fs-password-validity>
    
            </fs-grid-cell>
            <fs-grid-cell>
    
                <fs-form-field [type]="'password'"
                    [invalid]="passwordFormControls.confirm.touched && passwordFormControls.confirm.invalid">
                    <label for="confirmPassword">Confirm Password</label>
                    <input id="confirmPassword" type="password" [formControl]="passwordFormControls.confirm">
                </fs-form-field>
                <small class="mb-16"
                    *ngIf="passwordFormControls.confirm.touched && passwordFormControls.confirm.invalid"
                    fs-small-message [type]="'Error'">Please enter a valid password</small>
    
            </fs-grid-cell>
        </fs-grid>
    
        <div class="bottom-slot">
            <button fs-button [color]="'primary'" type="submit">Save Password</button>
        </div>
    </div>
</form>