import { Component, OnInit } from '@angular/core';
import { FsLoaderService } from './fs-loader.service';

@Component({
  selector: 'fs-loader',
  templateUrl: './fs-loader.component.html',
  styleUrls: ['./fs-loader.component.scss'],
})
export class FsLoaderComponent implements OnInit {
  constructor(public loaderService: FsLoaderService) {}

  ngOnInit(): void {}
}
