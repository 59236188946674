import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsLabelComponent } from './fs-label.component';

@NgModule({
  declarations: [FsLabelComponent],
  imports: [CommonModule],
  exports: [FsLabelComponent],
})
export class FsLabelModule {}
