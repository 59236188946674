import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmAccountState } from 'src/app/shared/enums/auth.enum';
import { FsGridOptions } from 'src/app/shared/modules/fs-controls/fs-grid/fs-grid.model';
import { FsLoaderService } from 'src/app/shared/modules/fs-controls/fs-loader/fs-loader.service';
import { FsPageAction } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-action';
import { FsPageFrameService } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-frame.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AWSResponseType } from 'src/app/shared/services/error-auth';
import { GlobalService } from 'src/app/shared/services/global.service';

//mohitzadeh+devplayer3@gmail.com
//Aa2551475!

@Component({
  selector: 'sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent extends FsPageAction {
  showMessage: boolean;

  signInFormControl = {
    email: new FormControl('', [
      Validators.required,
      Validators.maxLength(250),
      Validators.email,
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
    ]),
  };
  signInForm: FormGroup;
  gridOptions: FsGridOptions;

  constructor(
    frameService: FsPageFrameService,
    private formBuilder: FormBuilder,
    public loaderService: FsLoaderService,
    public authService: AuthService,
    private globalService: GlobalService,
    private router: Router
  ) {
    super(frameService);
    this.createForms();
    this.title = 'Sign In';
    this.gridOptions = new FsGridOptions({
      xsm: [[12], [12], [12], [12]],
      sm: [[12], [12], [12], [12]],
      md: [[6, 6], [12], [3]],
      lg: [[6, 6], [12], [3]],
      xl: [[4, 4], [12], [3]],
      xxl: [[4, 4], [12], [3]],
    });
    this.showMessage = false;
  }

  createForms(): void {
    this.signInForm = this.formBuilder.group({ ...this.signInFormControl });
  }

  async onSubmit(): Promise<void> {
    try {
      this.loaderService.loader = true;

      const email = this.signInFormControl.email.value.toLowerCase();
      const password = this.signInFormControl.password.value;

      const result1 = await this.authService.signIn(email, password);

      if (result1.error) {
        if (result1.type === AWSResponseType.UserNotConfirmed) {
          const result2 = await this.authService.reSendSignUpConfirm(email);

          if (result2.type === AWSResponseType.Success) {
            this.globalService.currentConfirmAccount =
              ConfirmAccountState.WaitToConfirm;

            this.showMessage = true;
          }
        } else {
          alert(result1.message);
        }
      } else {
        if (result1.type === AWSResponseType.UserIsExists) {
          this.globalService.player = await this.authService.getCompetePlayer();

          if (this.globalService.player) {
            this.authService.isAuthenticated = true;

            if (this.globalService.checkAccountIsCompleted()) {
              await this.checkSessionId();
              this.frameService.resetHistory();
            }

            return;
          } else {
            alert('Player not found.');
          }
        } else {
          alert(result1.message);
        }
      }

      this.authService.signOut();
    } catch (error) {
      console.log(error);
    } finally {
      this.loaderService.loader = false;
    }
  }

  async signOut(): Promise<void> {
    this.loaderService.loader = true;

    await this.authService.unautorized(['_fs_cp_page_his']);
    this.signInForm.reset();
    this.router.navigateByUrl('/');

    this.loaderService.loader = false;
  }

  async checkSessionId(): Promise<void> {
    const sessionId = window.localStorage.getItem('SessionId');

    if (sessionId?.length) {
      const joinResult = await this.authService.joinSession(sessionId);

      switch (joinResult.status.toLowerCase()) {
        case 'already joined':
          alert('Already Joined');
          break;
        case 'error':
          alert('Session has expired');
          break;
        case 'success':
          alert('Successful joined');
          break;
        default:
          break;
      }
    }
  }

  goContinue(): void {
    if (this.globalService.player) {
      if (!this.globalService.checkAccountIsCompleted()) {
        this.nextPage('/create-account');
      }
    }
  }
}
