<ng-container *ngIf="!pageLoader.loader">
    <ng-container *ngIf="applicationReady">

        <main>
            <fs-page-frame>

                <ng-container
                    *ngIf="!authService.isAuthenticated || !globalService.checkAccountIsCompleted(); else loggedInTemp">

                    <router-outlet></router-outlet>

                </ng-container>
                <ng-template #loggedInTemp>

                    <div class="page-flow">
                        <div class="ph-16">
                            <fs-alert [title]="'Welcome to Full Swing!'"
                                [message]="'Tap below to log in to your simulator.'"
                                [icon]="'fsi-cir-success-48'"></fs-alert>

                            <button fs-button [color]="'primary'" class="mb-16" type="button">
                                Continue
                            </button>
                            <a class="text-center" (click)="signOut()">Sign Out</a>
                        </div>
                    </div>

                </ng-template>

            </fs-page-frame>
        </main>

        <fs-loader></fs-loader>

    </ng-container>
</ng-container>

<fs-page-loader></fs-page-loader>