import { Injectable } from '@angular/core';
import {
  FsOverlayContentOptions,
  FsOverlayOptions,
  IBoundingClientRect,
} from './fs-controls-shared.model';
import { Subject, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FsControlService {
  private domClickListener = new Subject<any>();
  public domClickListener$ = this.domClickListener.asObservable();

  private destroyOverlay = new Subject<void>();
  public destroyOverlay$ = this.destroyOverlay.asObservable();

  private _activeOverlay: boolean;
  public get activeOverlay(): boolean {
    return this._activeOverlay;
  }
  public set activeOverlay(v: boolean) {
    this._activeOverlay = v;
  }

  constructor() {
    this._activeOverlay = false;
    this.createOverlayContainer();
    this.initialDOMClickListener();
  }

  private initialDOMClickListener(): void {
    fromEvent(window.document, 'click').subscribe((e) =>
      this.domClickListener.next(e)
    );
  }

  private _calcContentHeight(
    contentItemsCount: number,
    maxItemsPerView: number,
    forStyle: boolean = false
  ): number {
    let height: number = 0;

    if (maxItemsPerView < contentItemsCount) {
      height = maxItemsPerView * 36;
    } else {
      height = contentItemsCount * 36;
    }

    return forStyle
      ? height
      : height + 20 /*padding block*/ + 2 /*border width */;
  }

  private createOverlayContainer(): void {
    if (!document.getElementById('fs-overlay')) {
      let overlay = document.createElement('div');

      overlay.classList.add('fs-overlay');
      overlay.classList.add('d-none');
      overlay.style.width = `${document.body.clientWidth}px`;
      overlay.style.height = `${document.body.clientHeight}px`;
      overlay.id = 'fs-overlay';

      fromEvent(overlay, 'click').subscribe((_) => this.removeOverlayContent());

      document.body.appendChild(overlay);
    }
  }

  private createContentPlacement(
    options: FsOverlayContentOptions
  ): HTMLElement {
    let innerContent = document.createElement('div');

    innerContent.style.position = 'absolute';

    if (
      options.parentRect.left + options.parentOptions.width >=
      window.innerWidth
    )
      innerContent.style.right = `56px`;
    else innerContent.style.left = `${options.parentRect.left - 11}px`;

    let maxHeight = this._calcContentHeight(
      options.parentOptions.contentItemsCount,
      options.parentOptions.maxItemsPerView
    );

    if (
      options.parentRect.bottom + maxHeight + 26 >
      options.overlayContainer.clientHeight
    ) {
      innerContent.style.top = `${options.parentRect.top - maxHeight}px`;
    } else {
      innerContent.style.top = `${
        options.parentRect.top + (options.parentRect.height - 8)
      }px`;
    }

    innerContent.style.minWidth =
      options.parentOptions.width > 0
        ? `${options.parentOptions.width}px`
        : `${options.parentRect.width}px`;

    innerContent.style.height = `${options.parentRect.height}px`;
    innerContent.style.zIndex = '10000';

    return innerContent;
  }

  public renderOverlayContent(options: FsOverlayOptions): void {
    this._activeOverlay = true;

    let parentRect: IBoundingClientRect = options.parentEl
      .getBoundingClientRect()
      .toJSON();

    if (options.leftOffset) parentRect.left += options.leftOffset;
    if (options.topOffset) parentRect.top += options.topOffset;

    let overlay: HTMLElement = document.getElementById('fs-overlay');
    overlay.classList.remove('d-none');

    let overlayContent: HTMLElement = this.createContentPlacement(
      new FsOverlayContentOptions({
        overlayContainer: overlay,
        parentRect: parentRect,
        contentRect: options.contentRef.getBoundingClientRect().toJSON(),
        parentOptions: options,
      })
    );

    if (options.classes?.length) overlayContent.classList.add(options.classes);

    options.contentRef.classList.remove('d-none');
    options.contentRef.style.opacity = '1';
    options.contentRef.style.maxHeight = `${this._calcContentHeight(
      options.contentItemsCount,
      options.maxItemsPerView,
      true
    )}px`;

    overlayContent.appendChild(options.contentRef);
    overlay.appendChild(overlayContent);
  }

  public removeOverlayContent(): void {
    let overlay = document.getElementById('fs-overlay');

    document.querySelectorAll('#fs-overlay > *').forEach((child) => {
      overlay.removeChild(child);
    });
    overlay.classList.add('d-none');

    this._activeOverlay = false;
    this.destroyOverlay.next();
  }
}
