import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FsGridOptions } from 'src/app/shared/modules/fs-controls/fs-grid/fs-grid.model';
import { FsLoaderService } from 'src/app/shared/modules/fs-controls/fs-loader/fs-loader.service';
import { FsPageAction } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-action';
import { FsPageFrameService } from 'src/app/shared/modules/fs-controls/fs-page-frame/fs-page-frame.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent extends FsPageAction {
  gridOptions: FsGridOptions;
  isValidPassword: boolean;

  passwordFormControls = {
    current: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
    ]),
    new: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    confirm: new FormControl('', [
      Validators.required,
      Validators.maxLength(50),
    ]),
  };
  passwordForm: FormGroup;

  constructor(
    frameService: FsPageFrameService,
    private builder: FormBuilder,
    public loaderService: FsLoaderService
  ) {
    super(frameService);
    this.createForms();
    this.gridOptions = new FsGridOptions({
      xsm: [[12], [12], [12]],
      sm: [[12], [12], [12]],
      md: [[6, 6], [6]],
      lg: [[6, 6], [6]],
      xl: [[6, 6], [6]],
      xxl: [[6, 6], [6]],
    });
    this.isValidPassword = false;
    this.title = 'Change Password';
  }

  createForms(): void {
    this.passwordForm = this.builder.group({
      ...this.passwordFormControls,
    });
  }

  onSubmit(): void {}
}
