export class BaseDTO {
  id?: number;
  created?: Date;
  modified?: Date;

  constructor(model: BaseDTO) {
    this.id = model.id || 0;
    this.created = model.created || (null as any);
    this.modified = model.modified || (null as any);
  }
}
