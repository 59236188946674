import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsGridComponent } from './fs-grid.component';
import { FsGridCellComponent } from './fs-grid-cell/fs-grid-cell.component';

@NgModule({
  declarations: [FsGridComponent, FsGridCellComponent],
  imports: [CommonModule],
  exports: [FsGridComponent, FsGridCellComponent],
})
export class FsGridModule {}
