import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import {
  CodeDeliveryDetails,
  ISignUpResult,
  CognitoUser,
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { HttpService } from './http.service';
import { removeStorageItemsByExclude } from '../helper-functions';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { JoinResponseModel } from '../models/api.model';
import { ApplicationIdEnum } from '../enums/api.enum';
import { UserAccountModel } from '../models/account';
import { ConfirmUserModel } from '../models/parameters';
import { AWSErrorHandler, AWSResponseType, AWSResponse } from './error-auth';
import {
  CreatePlayerRequest,
  PlayerResult,
  UpdatePlayerRequest,
} from '../models/player.model';
import { ListResult, PageOptions } from '../models/app.model';
import { LoggerService } from './logger.service';
import { GlobalService } from './global.service';
import { ConfirmAccountState } from '../enums/auth.enum';
import { Gender } from '../enums/gender.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isAuthenticated: boolean;
  public get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }
  public set isAuthenticated(v: boolean) {
    this._isAuthenticated = v;
  }

  constructor(
    private httpService: HttpService,
    private loggerService: LoggerService,
    private globalService: GlobalService
  ) {
    this._isAuthenticated = false;
  }

  public async isTokenValid(): Promise<boolean> {
    try {
      var isValid = (await Auth.currentSession()).isValid();
      return isValid;
    } catch (err) {
      return false;
    }
  }

  public async refreshToken(): Promise<boolean> {
    try {
      const user = await this.getCurrentUser();
      var idToken = user.getSignInUserSession().getIdToken();
      user.refreshSession(
        user.getSignInUserSession().getRefreshToken(),
        this.tokenRefreshed
      );
      return true;
    } catch (err) {
      return false;
    }
  }

  public async getTokenExpiration(): Promise<Date> {
    try {
      const user = await this.getCurrentUser();
      var unix_timestamp = user
        .getSignInUserSession()
        .getIdToken()
        .getExpiration();
      // Create a new JavaScript Date object based on the timestamp
      // multiplied by 1000 so that the argument is in milliseconds, not seconds.
      var date = new Date(unix_timestamp * 1000);
      return date;
    } catch (err) {
      return null;
    }
  }

  public async getUserId(): Promise<string> {
    try {
      const attr = await this.getUserInfo();
      var idToken = attr.username;
      return idToken;
    } catch (err) {
      //console.log(err);
      return null;
    }
  }

  public async getIdToken(): Promise<CognitoIdToken> {
    try {
      const user = await this.getCurrentUser();
      var idToken = user.getSignInUserSession().getIdToken();
      return idToken;
    } catch (err) {
      return null;
    }
  }

  public async getCurrentSession(): Promise<CognitoUserSession> {
    return await Auth.currentSession();
  }

  public async getCurrentUser(): Promise<CognitoUser> {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (err) {
      return null;
    }
  }

  public async getUserInfo(): Promise<any> {
    try {
      const res = await Auth.currentUserInfo();
      return res;
    } catch (err) {
      //console.log('Current user info failed to fetch', err);
      return err;
    }
  }

  public async getUserAttributebyKey(key: string): Promise<string> {
    try {
      let currentUserInfo = await Auth.currentUserInfo();
      let value = currentUserInfo.attributes[key];
      return value;
    } catch (err) {
      console.error('error fetching user info: ', err);
      return null;
    }
  }

  public async signUp(
    user: UserAccountModel
  ): Promise<AWSResponse<ISignUpResult>> {
    try {
      const result = await Auth.signUp({
        password: user.password,
        username: user.email,
        clientMetadata: {
          name: `${user.firstName} ${user.lastName}`,
        },
        attributes: {
          name: `${user.firstName} ${user.lastName}`,
        },
      });

      return new AWSResponse({
        type: AWSResponseType.Success,
        data: result,
      });
    } catch (error) {
      return AWSErrorHandler.result(error);
    }
  }

  public async confirmSignUp(confirm: ConfirmUserModel): Promise<AWSResponse> {
    try {
      const response = await Auth.confirmSignUp(confirm.username, confirm.code);

      if (typeof response === 'string') {
        if (response === 'SUCCESS')
          return new AWSResponse({
            type: AWSResponseType.Success,
          });
      }

      return new AWSResponse({
        type: AWSResponseType.Unknown,
      });
    } catch (error) {
      console.error(error);
      return AWSErrorHandler.result(error);
    }
  }

  public async signIn(
    userName: string,
    password: string
  ): Promise<AWSResponse> {
    try {
      const user = await Auth.signIn(userName, password);

      if (
        user.challengeName &&
        user.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        return new AWSResponse({
          type: AWSResponseType.NewPasswordRequired,
        });
      } else if (user && user.attributes && user.attributes.sub?.length) {
        return new AWSResponse({
          type: AWSResponseType.UserIsExists,
        });
      } else {
        return new AWSResponse({
          type: AWSResponseType.UserNotExist,
        });
      }
    } catch (err) {
      return AWSErrorHandler.result(err);
    }
  }

  public async reSendSignUpConfirm(
    username: string
  ): Promise<AWSResponse<CodeDeliveryDetails>> {
    try {
      const result = await Auth.resendSignUp(username);

      if (result?.CodeDeliveryDetails) {
        return new AWSResponse({
          type: AWSResponseType.Success,
          data: result?.CodeDeliveryDetails,
        });
      }

      console.log(result);
      return new AWSResponse({
        type: AWSResponseType.Unknown,
      });
    } catch (error) {
      return AWSErrorHandler.result(error);
    }
  }

  public async completeNewPassword(
    userName: string,
    code: string,
    password: string
  ) {
    return Auth.signIn(userName, code)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          return Auth.completeNewPassword(
            user, // the Cognito User Object
            password // the new password
          )
            .then((res) => {
              return true;
            })
            .catch((e) => {
              console.error(e);
              return false;
            });
        } else {
          // at this time the user is logged in if no MFA required

          //console.log(user);
          return true;
          // other situations
        }
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  }

  public async changePassword(oldPassword: string, newPassword: string) {
    try {
      const res = await Auth.changePassword(
        await this.getCurrentUser(),
        oldPassword,
        newPassword
      );
      return res;
    } catch (err) {
      //console.log('Current user info failed to fetch', err);
      return err;
    }
  }

  public async forgotPassword(userName: string): Promise<AWSResponse> {
    try {
      const result = await Auth.forgotPassword(userName);

      if (result?.CodeDeliveryDetails) {
        return new AWSResponse({
          type: AWSResponseType.Success,
          data: result?.CodeDeliveryDetails,
        });
      }

      return result;
    } catch (error) {
      return AWSErrorHandler.result(error);
    }
  }

  public async forgotPasswordSubmit(
    userName: string,
    confirmationCode: string,
    password: string
  ): Promise<AWSResponse> {
    try {
      const response = await Auth.forgotPasswordSubmit(
        userName,
        confirmationCode,
        password
      );

      if (typeof response === 'string') {
        if (response === 'SUCCESS')
          return new AWSResponse({
            type: AWSResponseType.Success,
          });
      }

      return new AWSResponse({
        type: AWSResponseType.Unknown,
        message: response,
      });
    } catch (error) {
      return AWSErrorHandler.result(error);
    }
  }

  public async signOut(): Promise<void> {
    try {
      await Auth.signOut({ global: false });
    } catch (error) {
      console.error(error);
    }
  }

  private tokenRefreshed(e, r) {
    var idToken = r.getIdToken();
  }

  public async isCompetePlayer(): Promise<boolean> {
    try {
      const userSession = await this.getCurrentSession();
      const groups: Array<string> =
        userSession.getAccessToken().payload['cognito:groups'];

      for (let index = 0; index < groups.length; index++) {
        if (groups[index] === 'competeplayer') return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async isAdmin(admin: string = null): Promise<boolean> {
    try {
      const userSession = await this.getCurrentSession();
      const groups: Array<string> =
        userSession.getAccessToken().payload['cognito:groups'];

      if (admin?.length) return groups.find((f) => f === admin) ? true : false;

      return groups.filter((f) => f.search('admin') > 0)?.length > 0;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async getCognitoGroups(): Promise<Array<string>> {
    try {
      const userSession = await this.getCurrentSession();
      const groups: Array<string> =
        userSession.getAccessToken()?.payload['cognito:groups'];

      return groups;
    } catch (error) {
      return null;
    }
  }

  public async joinSession(sessionId: string): Promise<JoinResponseModel> {
    try {
      // let sessionId = '';

      // if (this.queryData && this.queryData?.sessionId?.length) {
      //   sessionId = this.queryData?.sessionId;
      // } else {
      //   sessionId = window.localStorage.getItem('SessionId');

      //   if (!sessionId?.length) {
      //     return;
      //   }
      // }

      const userInfo = await lastValueFrom(
        this.httpService.getItems<JoinResponseModel>(
          ApplicationIdEnum.FS_AUTH,
          `/session/${sessionId}/join`
        )
      );

      console.log(userInfo);

      // if (userInfo) {
      //   if (
      //     userInfo.status === 'Success' ||
      //     userInfo.status === 'Already Joined'
      //   ) {
      //     window.localStorage.setItem('SessionId', sessionId);
      //     return;
      //   }
      // }

      return userInfo;
    } catch (error) {
      console.log(error);
      return new JoinResponseModel({
        status: 'Error',
      });
    } finally {
      window.localStorage.removeItem('SessionId');
    }
  }

  public async getCompetePlayer(): Promise<PlayerResult> {
    try {
      const players = await lastValueFrom(
        this.httpService.getItemByQuery<ListResult<PlayerResult>>(
          ApplicationIdEnum.FS_COMPETE,
          '/player-manager',
          {
            ...new PageOptions({}),
          }
        )
      );

      players.data[0].gender = Gender.Male;

      if (players.data?.length === 1) {
        this.loggerService.printObject('Compete Player', players.data[0]);
        return players.data[0];
      } else return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async createCompetePlayer(
    request: CreatePlayerRequest
  ): Promise<PlayerResult> {
    try {
      const result = await lastValueFrom(
        this.httpService.createItem<PlayerResult>(
          ApplicationIdEnum.FS_COMPETE,
          request,
          '/player-manager'
        )
      );

      if (result?.id) {
        return result;
      }

      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async updateCompetePlayer(
    request: UpdatePlayerRequest
  ): Promise<boolean> {
    try {
      request.gender = null as any;

      const resultSignUp = await lastValueFrom(
        this.httpService.updateItem<PlayerResult>(
          ApplicationIdEnum.FS_COMPETE,
          request,
          '/player-manager'
        )
      );

      if (resultSignUp?.id) {
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async unautorized(excludeStorageKeys?: string[]): Promise<void> {
    try {
      await this.signOut();
    } catch (error) {
      console.error(error);
    } finally {
      this._isAuthenticated = false;
      this.globalService.player = null;
      this.globalService.currentConfirmAccount =
        ConfirmAccountState.BeforeCreation;
      removeStorageItemsByExclude(excludeStorageKeys);
    }
  }
}
